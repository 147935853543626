import React, { useState } from 'react';
import '../Components/ContactPage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { SupportAgent, AttachMoney, Timeline } from '@mui/icons-material';
import { ReactComponent as NakwiSvg } from '../Images/nakwi-logo.svg';

function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    email: '',
    techStack: '',
    projectRequirements: '',
    nda: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('form_submit.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({
          name: '',
          contactNumber: '',
          email: '',
          techStack: '',
          projectRequirements: '',
          nda: false,
        });
      } else {
        alert('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  return (
    <div className='contact-page-main'>
      <div className="contact-page-container">
        <Container>
          <Row>
            <Col lg='8'>
              <div className="contact-page-heading">
                <h6>Your success is our priority.</h6>
                <h4>Let's Work Together</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="contact-page-form">
                  <h4>Contact Info</h4>
                  <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder='Name' required />
                  <input type="number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} placeholder='Contact Number' required />
                  <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' required />
                </div>
                <div className="contact-page-form">
                  <h4>About Project</h4>
                  <input type="text" name="techStack" value={formData.techStack} onChange={handleChange} placeholder='Enter Your Tech Stack' required />
                  <input type="text" name="projectRequirements" value={formData.projectRequirements} onChange={handleChange} placeholder='Fill Your Project Requirement' required />
                </div>
                <div className="contact-page-btn">
                  <div className="contact-page-check">
                    <input type="checkbox" id="nda" name="nda" checked={formData.nda} onChange={handleChange} />
                    <label htmlFor="nda" className="nda-text">Please send me a non-disclosure agreement.</label>
                  </div>
                  <div className="contact-page-button">
                    <button id='contact-page-button' type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </Col>
            <Col lg='4'>
              <div className="why-nakwi">
                <h1>Why <NakwiSvg/>?</h1>
                <div className="why-nakwi-decs">
                  <h5><SupportAgent /> Quick Support</h5>
                  <p>Receive prompt assistance and support whenever you need it.</p>
                  <hr />
                  <h5><AttachMoney /> Competitive Pricing</h5>
                  <p>Unlock unbeatable value with our competitive rates and cost-effective solutions.</p>
                  <hr />
                  <h5><Timeline /> Agile Process</h5>
                  <p>Stay informed every step of the way with our transparent and agile processes.</p>
                </div>
                <hr />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ContactPage;
