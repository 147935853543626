import { AccessTime, CheckCircle, DesktopMac, GridView, MobileFriendly, PhoneInTalk, Star, Web, } from '@mui/icons-material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/UIDesign.css'
import UITabs from '../../Components/UITabs'
import ContactPage from '../../Components/ContactPage'
import ContactFooter from '../../Components/ContactFooter'

function UIDesign() {
    return (
        <div className='Ui-main'>
            <div className='ui-details-heading'>
                            <div className='web-main-left'>
                                <h6>UI/UX Design</h6>
                                <h1>Creative UI/UX Design Services</h1>
                                <p>Leveraging a talented team of UI/UX designers, nakwi elevates application design beyond conventional standards, crafting stunning and engaging interfaces. Our goal is to provide an unparalleled user experience by seamlessly integrating AI/ML techniques with innovative UI/UX design strategies.</p>
                            </div>
                            <div className='main-left-inside'>
                                <Container>
                                    <Row>
                                        <Col lg='6'>
                                            <span><AccessTime /></span>
                                            <p>Global Time-Zone Alignment</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><PhoneInTalk /></span>
                                            <p>Complimentary Consultation</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><Star /></span>
                                            <p>Over 8 Years of Experience</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><CheckCircle /></span>
                                            <p>Over 90 Successful Projects</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
            </div>

            {/* design expert section  */}

            <div className='Design-expert-main'>
                <div className='design-expert-heading'>
                    <h1>Our Digital Product Design Expertise</h1>
                    <p>Our expert UI/UX design team places user needs at the forefront, creating valuable, engaging, and efficient experiences. From onboarding to feedback, our products are designed to provide seamless interactions that function harmoniously.</p>
                </div>
                <div className='design-expert-container'>
                    <Container>
                        <Row>
                            <Col lg='3' md='6' sm='6'>
                                <div className='design-container-inside'>
                                    <span><Web /></span>
                                    <h1>Website Design and Redesign</h1>
                                    <p>We excel in crafting stunning website designs from the ground up that captivate audiences. Additionally, we offer comprehensive redesign services to enhance the appearance and functionality of your existing website.</p>
                                </div>
                            </Col>
                            <Col lg='3' md='6' sm='6'>
                                <div className='design-container-inside'>
                                    <span><DesktopMac /></span>
                                    <h1>Web App UI/UX Design</h1>
                                    <p>At Nakwi, we harness the creativity and dedication of our designers to craft visually appealing web app UI/UX designs, enabling you to dramatically enhance the reach and impact of your web application.</p>
                                </div>
                            </Col>
                            <Col lg='3' md='6' sm='6'>
                                <div className='design-container-inside'>
                                    <span><MobileFriendly /></span>
                                    <h1>Mobile App UI/UX Design</h1>
                                    <p>In addition to web applications, we specialize in creating engaging UI/UX designs for mobile apps. Regardless of the platform your mobile app runs on, our expertise ensures that it will stand out and make a lasting impression.</p>
                                </div>
                            </Col>
                            <Col lg='3' md='6' sm='6'>
                                <div className='design-container-inside'>
                                    <span><GridView /></span>
                                    <h1>Wireframing and Prototyping</h1>
                                    <p>You can also take advantage of our wireframing and prototyping services, providing you with a clear vision of how your web or mobile app will look and function once fully implemented.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* ui tabs  */}

            <div className='ui-tabs-main'>
                <div className='ui-tabs-heading'>
                    <h1>Design Tools We Use</h1>
                    <p>We possess demonstrated expertise in utilizing the most advanced UI/UX tools and technologies, encompassing a wide array of options, including but not limited to the following:</p>
                </div>
                <div className='ui-tabs'>
                    <UITabs/>
                </div>
            </div>

            <div>
                <ContactPage/>
                <ContactFooter/>
            </div>

        </div>
    )
}

export default UIDesign
