import React from 'react';
import '../src/App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/Pages/Home'
import AboutUs from './Pages/AboutUs';
import TermsAndConditions from './Components/TermConditionPage';
import WebDevelopment from './Pages/Services/WebDevelopment';
import MobileDevelopment from './Pages/Services/MobileDevelopment';
import Devops from './Pages/Services/Devops';
import UIDesign from './Pages/Services/UIDesign';
import QADevelopment from './Pages/Services/QADevelopment';
import InfraDevelopment from './Pages/Services/InfraDevelopment';
import Career from './Components/Career';
import TopNavbar from './Components/TopNavbar';
import Quoit from './Components/Quoit';
const App = () => {
    return (
        <Router basename='/'>
            <TopNavbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/web-development" element={<WebDevelopment />} />
                <Route path="/mobile-development" element={<MobileDevelopment />} />
                <Route path="/devops" element={<Devops />} />
                <Route path="/qa-testing" element={<QADevelopment />} />
                <Route path="/uiux-design" element={<UIDesign />} />
                <Route path="/infrastructure" element={<InfraDevelopment />} />      
                <Route path="/career" element={<Career />} />  
                <Route path="/quoit" element={<Quoit />} />          
            </Routes>
        </Router>
    );
};

export default App;
