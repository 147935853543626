import { ArrowForward } from '@mui/icons-material'
import React from 'react'
import './Home.css'
import { Col, Container, Row } from 'react-bootstrap'
import Homesection2 from '../Components/Homesection2'
import HomeSection3 from '../Components/HomeSection3'
import HomeBlogs from '../Components/HomeBlogs'
import ContactPage from '../Components/ContactPage'
import ContactFooter from '../Components/ContactFooter'
import { ReactComponent as HomeSvg } from '../Images/home.svg'



function Home() {
  return (
    <>
      <div className='Home'>
        <div className='Home-container'>
          <Container>
            <Row>
              <Col lg='6'>
                <div className='Heading'>
                  <h4>We Conceptualize,<br /> Craft, and Conquer.</h4>
                  <p>Unleash your creativity with a top-tier software development company. Partner with nakwi, where ideas blossom and innovation drives your path to success.</p>
                  <button>Get In Touch <ArrowForward /></button>
                </div>
              </Col>
              <Col lg='6'>
                <div className='Home-svg-main'>
                  <HomeSvg />
                </div>

              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='Homesection2'>
        <Homesection2 />
      </div>


      <div className='Homesection3'>
        <HomeSection3 />
      </div>
      <div className='Homesection3'>
        <HomeBlogs />
      </div>
      <div className='contactpage'>
        <ContactPage />
      </div>
      <div className='footer'>
        <ContactFooter />
      </div>
    </>
  )
}

export default Home
