import React, { useState } from 'react';
import '../Components/HomeSection3.css';
import SearchIcon from '@mui/icons-material/Search';
import PaletteIcon from '@mui/icons-material/Palette';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import BuildIcon from '@mui/icons-material/Build';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Col, Container, Row } from 'react-bootstrap';

function HomeSection3() {
    const [activeStep, setActiveStep] = useState('Analysis');
    const stepDetails = {
        'Analysis': {
            title: 'Analysis',
            icontitle: <SearchIcon />,
            description: 'A thorough analysis is performed with the help of requirement elicitation and site reliability engineering to understand the features of the project in a better way.',
            icon: <SearchIcon />
        },
        'Design': {
            title: 'Design',
            icontitle: <PaletteIcon />,
            description: 'The design phase focuses on creating a structured and efficient architecture with user experience in mind.',
            icon: <PaletteIcon />
        },
        'Extract': {
            title: 'Extract',
            icontitle: <DataUsageIcon />,
            description: 'Data extraction is performed to retrieve the most valuable data for decision-making and automation.',
            icon: <DataUsageIcon />
        },
        'Automate': {
            title: 'Automate',
            icontitle: <BuildIcon />,
            description: 'We automate processes to increase efficiency and reduce manual intervention.',
            icon: <BuildIcon />
        },
        'Develop': {
            title: 'Develop',
            icontitle: <LaptopMacIcon />,
            description: 'Our development team brings the project to life, writing clean, scalable code.',
            icon: <LaptopMacIcon />
        },
        'Accelerate': {
            title: 'Accelerate',
            icontitle: <RocketLaunchIcon />,
            description: 'We accelerate the project delivery by optimizing workflows and ensuring timely completion.',
            icon: <RocketLaunchIcon />
        }
    };

    return (
        <div className='work-flow-main'>
            <div className="work-flow-heading">
                <h1>Our Workflow</h1>
                <p>We pride ourselves on our unwavering commitment to delivering innovative AI/ML solutions and data-centric services. Our approach emphasizes diligence and precision at every stage of product engineering and development, ensuring exceptional results for our valued clients.</p>
            </div>
            <Container>
                <Row>
                    <Col lg='6' md='4'>
                        <div className="workflow-container">
                            {Object.keys(stepDetails).map((step) => (
                                <React.Fragment key={step}>
                                    <div className='workflow-container-steps'>
                                        <div className={`icon-title ${activeStep === step ? 'active-icon' : ''}`}>
                                            {stepDetails[step].icontitle}
                                        </div>
                                        <div
                                            className={`workflow-step ${activeStep === step ? 'active' : ''}`}
                                            onClick={() => setActiveStep(step)}
                                        >
                                            {step}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className='workflow-line'></div>
                    </Col>
                    <Col lg='6'>
                        <div className="workflow-details">
                            <div className="workflow-headings">
                                <span className='icon'>{stepDetails[activeStep].icon}</span>
                                <h1>{stepDetails[activeStep].title}</h1>
                                <p>{stepDetails[activeStep].description}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HomeSection3;
