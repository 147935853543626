import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as ContactSvg } from '../Images/quoit.svg'
import ContactPage from './ContactPage'
import ContactFooter from './ContactFooter'
import '../Components/Quoit.css'

function Quoit() {
    return (
        <div className='quoit-main'>
            <div className="quoit-main-details">
                <Container>
                    <Row>
                        <Col lg='6'>
                            <div className='quoit-main-left'>
                                <h1>Start building your dream project today. Contact us for a free consultation!</h1>
                                <p>We thrive on solving tough challenges with creative solutions. Share your project with us, and let's collaborate to deliver outstanding results.</p>
                            </div>
                        </Col>
                        <Col lg='6'>
                            <div className="quoit-svg">
                                <ContactSvg />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-section-quoit">
                <ContactPage />
                <ContactFooter />
            </div>
        </div>
    )
}

export default Quoit
