import DataArrayIcon from '@mui/icons-material/DataArray';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DevicesIcon from '@mui/icons-material/Devices';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ScienceIcon from '@mui/icons-material/Science';
import BuildIcon from '@mui/icons-material/Build';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ReactComponent as TechnologySvg } from '../../Images/technology.svg'

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/WebDevelopment.css'
import ContactPage from '../../Components/ContactPage'
import ContactFooter from '../../Components/ContactFooter'
import { AccessTime, Build, Business, CheckCircle, Cloud, Computer, PhoneInTalk, PortableWifiOff, Star, SupportAgent } from '@mui/icons-material';

function WebDevelopment() {
  const cardData = [
    {
      title: 'Progressive Web App Solutions',
      description: 'We excel in building highly functional PWAs to offer accessible and user-friendly online experiences to our valued customers.',
      icon: <Computer />
    },
    {
      title: 'Enterprise-Grade Web Applications',
      description: 'nakwi leverages client-focused agile strategies to develop cutting-edge enterprise web applications, empowering your business to stay ahead in the competitive landscape.',
      icon: <Business />
    },
    {
      title: 'Scalable Web Portals',
      description: 'By choosing our web portals, you gain the advantages of scalability, cost-effectiveness, security, and high availability, ensuring your investment delivers long-term value.',
      icon: <PortableWifiOff />
    },
    {
      title: 'Cloud-Powered Web Apps',
      description: 'Utilize our cloud hosting services for your web apps and unlock new opportunities for growth by harnessing the power of cloud-based web application development.',
      icon: <Cloud />
    },
    {
      title: 'Supply Chain Optimization Apps',
      description: 'Enhance the efficiency of your communication and collaboration with suppliers, manufacturers, distributors, retailers, and customers through our comprehensive supply chain management services.',
      icon: <Build />
    },
    {
      title: 'Web Application Strategy & Consulting',
      description: 'We can transform your initial concepts into clear technical requirements, complete with resource and cost estimates, ready to be advanced to the design and development phases.',
      icon: <SupportAgent />
    }
  ];
  const roles = [
    {
      title: "Designer",
      description: "Our UI/UX designers work relentlessly to beautify the outlook and overall usability of your web applications. Their efforts are reflected clearly in the finished software product.",
    },
    {
      title: "Quality Assurance Engineer",
      description: "The QA engineers at nakwi believe in delivering high-quality web-based projects to ensure robustness and efficiency by incorporating manual and automated testing methodologies.",
    },
    {
      title: "Product Managerr",
      description: "To ensure a smooth flow of the overall web app development project, you can completely trust the product managers at nakwi.",
    },
  ];

  const developerRoles = [
    {
      title: "Backend Developer",
      description: "Our backend developers are committed to presenting a clear picture of the database structures, data processing, and third-party integrations thus guaranteeing security and efficiency.",
    },
    {
      title: "Frontend Developer",
      description: "Our front-end developers work hand in hand with the designers to create compelling web designs that are sure to drive traffic and increase conversions.",
    },
  ];

  return (
    <div className='web-development-main'>
      <div className='web-main-container'>
        <div className='web-main-left'>
          <h6>Web App Development</h6>
          <h1>Take Your Digital Business to the Next Level.</h1>
          <p>Create highly innovative, and scalable web app frameworks with us to support your business growth. We provide custom web app development services globally for creating responsive web interfaces to guarantee a high level of customer satisfaction.</p>
        </div>
        <div className='main-left-inside'>
          <Container>
            <Row>
              <Col lg='6'>
                <span><AccessTime /></span>
                <p>Global Time-Zone Alignment</p>
              </Col>
              <Col lg='6'>
                <span><PhoneInTalk /></span>
                <p>Complimentary Consultation</p>
              </Col>
              <Col lg='6'>
                <span><Star /></span>
                <p>Over 8 Years of Experience</p>
              </Col>
              <Col lg='6'>
                <span><CheckCircle /></span>
                <p>Over 120 Successful Projects</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* web service */}
      <div className='web-services-main'>
        <div className='web-services-details'>
          <h1>End-to-End Web Application Solutions</h1>
          <p>Our goal is to master every facet of web app development, ensuring that nakwi becomes the top choice for clients seeking a reliable and innovative agency.</p>
        </div>
        <div className='web-services-container'>
          <Container>
            <Row>
              {cardData.map((card, index) => (
                <Col lg='4' key={index}>
                  <span>{card.icon}</span>
                  <h2>{card.title}</h2>
                  <hr />
                  <p>{card.description}</p>
                </Col>
              ))}
            </Row>
          </Container>
          <div className='web-services-butoon'>
            <button>Hire Dedicated Development Team</button>
          </div>
        </div>
      </div>

      {/* project scope section */}

      <div className='project-scope-main'>
        <div className='project-scope-details'>
          <div className='project-scope-heading'>
            <h1>Form a Strategic Development Team to Protect Your Project Deliverables.</h1>
            <p>Prevent scope creep, frequent delays, and unplanned changes by building an exceptional development team. Here is what a success-driven development team looks like.</p>
          </div>
          <div className='project-scope-overview'>
            <div className="timeline-container">
              <div className="role-column">
                {roles.map((role, index) => (
                  <div className="role" key={index}>
                    <h2>{role.title}</h2>
                    <p>{role.description}</p>
                  </div>
                ))}
              </div>

              <div className="timeline">
                {[1, 2, 3, 4, 5].map((number) => (
                  <div className="timeline-item" key={number}>
                    <span className="circle">{number}</span>
                    <span className="line"></span>
                  </div>
                ))}
              </div>

              <div className="role-column">
                {developerRoles.map((role, index) => (
                  <div className="role" key={index}>
                    <h2>{role.title}</h2>
                    <p>{role.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* web skills section  */}

      <div className='web-skills-main'>
        <div className='web-skills-details'>
          <div className='webskills-heading'>
            <h1>Advanced Tools and Technologies We Command</h1>
            <p>
              As a leading custom web application development agency, we are adept at creating remarkable web applications, web portals, and SaaS products, harnessing advanced technology to drive our clients' success.
            </p>
          </div>
          <div className='web-skills-container'>
            <Container>
              <Row>
                <Col lg='6'>
                  <div className='technolgy-img-svg'>
                    <TechnologySvg />
                  </div>
                </Col>
                <Col lg='6'>
                  <Container>
                    <Row>
                      <Col lg='6' md='4' xs='6'>
                        <span><DataArrayIcon /></span>
                        <h4>Big Data</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><AnalyticsIcon /></span>
                        <h4>Data Science</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><DevicesIcon /></span>
                        <h4>Internet Of Things</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><RemoveRedEyeIcon /></span>
                        <h4>Computer Vision</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><ScienceIcon /></span>
                        <h4>Machine Learning</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><BuildIcon /></span>
                        <h4>Augmented Reality</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><VrpanoIcon /></span>
                        <h4>Virtual Reality</h4>
                      </Col>
                      <Col lg='6' md='4' xs='6'>
                        <span><AccountTreeIcon /></span>
                        <h4>Blockchain</h4>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div>
        <ContactPage />
        <ContactFooter />
      </div>

    </div>
  )
}

export default WebDevelopment
