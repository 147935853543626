import { AccessTime, Build, Business, CheckCircle, Cloud, Computer, PhoneInTalk, PortableWifiOff, Star, SupportAgent } from '@mui/icons-material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/Mobile.css'

import ContactFooter from '../../Components/ContactFooter';
import ContactPage from '../../Components/ContactPage';

function MobileDevelopment() {

    const cardData = [
        {
            title: 'Android Application Solutions',
            description: 'Our Android app development services encompass everything from application design to deployment on the Play Store. We leverage technologies such as Java, Kotlin, Architecture Design Patterns, Reactive Programming, Dependency Injection, and Analytics to achieve this.',
            icon: <Computer />
        },
        {
            title: 'iOS Application Expertise',
            description: 'Our team is proficient in the latest iOS app development technologies, including Swift, Objective C, and Analytics. Our adaptability in this field is evident from our experience across diverse industries such as IoT, Fintech, Healthcare, Social Media, Education, and Augmented Reality.',
            icon: <Business />
        },
        {
            title: 'Cross-Platform Hybrid App Development',
            description: 'Our dedicated team specializes in developing hybrid applications that combine the best features of both native and web apps. With our hybrid app development services, you can write your code once and deploy it across multiple platforms, including Android, iOS, and Windows.',
            icon: <PortableWifiOff />
        },
        {
            title: 'Robust Backend Development',
            description: 'We recognize the significance of having fully compatible and secure back-end solutions paired with an attractive front-end design. To achieve this, we utilize technologies such as Python Django, Ruby on Rails, Node.js, JSON, REST, and SSL encryption. With these services, you can ensure your back-end is just as robust as your front-end.',
            icon: <Cloud />
        },
        {
            title: 'Scalable & Modular App Solutions',
            description: 'At nakwi, we prioritize the modularity of mobile applications to ensure they can be effortlessly scaled and upgraded according to our clients needs. This approach also facilitates seamless integration with other third-party applications.',
            icon: <Build />
        },
        {
            title: 'Comprehensive QA & Testing Services',
            description: 'We are committed to providing mobile app solutions that adhere to the highest software quality standards. To ensure this, we conduct thorough testing processes, including Functional Testing, White and Black Box Testing, and Espresso UI Testing. Our toolkit for achieving these goals includes industry-leading tools such as Selenium, Appium, JUnit, and XC Test.',
            icon: <SupportAgent />
        }
    ];
    return (
        <div className='mobile-development-main'>
            <div className='web-main-container'>
                <div className='web-main-left'>
                    <h6>Mobile App Development</h6>
                    <h1>Catering to Your Daily Operational Demands</h1>
                    <p>Our mobile app developers are committed to creating robust mobile apps that not only deliver the best user experience but also compete to generate great corporate revenues.</p>
                </div>
                <div className='main-left-inside'>
                    <Container>
                        <Row>
                            <Col lg='6'>
                                <span><AccessTime /></span>
                                <p>Global Time-Zone Alignment</p>
                            </Col>
                            <Col lg='6'>
                                <span><PhoneInTalk /></span>
                                <p>Complimentary Consultation</p>
                            </Col>
                            <Col lg='6'>
                                <span><Star /></span>
                                <p>Over 8 Years of Experience</p>
                            </Col>
                            <Col lg='6'>
                                <span><CheckCircle /></span>
                                <p>Over 120 Successful Projects</p>
                            </Col>
                        </Row>
                    </Container>
                </div>


            </div>

            {/* web service */}
            <div className='mobile-services-main'>
                <div className='web-services-details'>
                    <h1>Custom-Built Mobile App Solutions</h1>
                    <p>The journey of mobile app development is both exciting and complex, and nakwi offers expertise in every crucial aspect of this dynamic field.</p>
                </div>
                <div className='web-services-container'>
                    <Container>
                        <Row>
                            {cardData.map((card, index) => (
                                <Col lg='4' key={index}>
                                    <span>{card.icon}</span>
                                    <h2>{card.title}</h2>
                                    <hr />
                                    <p>{card.description}</p>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <div className='web-services-butoon'>
                        <button>Hire Dedicated Development Team</button>
                    </div>
                </div>
            </div>

            {/* advanced skills */}

            <div className='skills-main'>
                <div className='skills-heading'>
                    <h1>Our Competence in Next-Gen Tech Stacks</h1>
                    <p>As a top-tier mobile application development company, we excel in creating innovative mobile apps and mobile-based software solutions delivered as a service. Our expertise, combined with cutting-edge technologies, drives the success of our clients.</p>
                </div>
                <div className='skills-container'>
                    <Container>
                        <Row>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg' alt='' /></span>
                                <h3>ReactJs</h3>
                            </Col>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/android/android-icon.svg' alt='' /></span>
                                <h3>Android</h3>
                            </Col>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/apple/apple-icon.svg' alt='' /></span>
                                <h3>iOS</h3>
                            </Col>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/swift/swift-icon.svg' alt='' /></span>
                                <h3>Swift</h3>
                            </Col>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg' alt='' /></span>
                                <h3>Kotlin</h3>
                            </Col>
                            <Col>
                                <span><img src='https://www.vectorlogo.zone/logos/apple_objectivec/apple_objectivec-icon.svg' alt='' /></span>
                                <h3>Objective-C</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <ContactPage />
                <ContactFooter />
            </div>

        </div>
    )
}

export default MobileDevelopment
