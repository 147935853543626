import React from 'react';
import '../Components/Term.css'
import ContactFooter from './ContactFooter';
import ContactPage from './ContactPage';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p><strong>Last updated: [Date]</strong></p>

      <p>Welcome to Nakwi. By accessing or using our website, 
      [www.yourcompanywebsite.com] (the "Site"), you agree to comply with and be bound by 
      the following terms and conditions (the "Terms"). Please review these Terms carefully. 
      If you do not agree to these Terms, you should not use this Site.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing and using this website, you accept and agree to be bound by the terms 
      and provisions of this agreement. In addition, when using our services, you shall be 
      subject to any posted guidelines or rules applicable to such services.</p>

      <h2>2. Use of the Site</h2>
      <p>You agree to use the Site only for lawful purposes and in a way that does not 
      infringe the rights of, restrict, or inhibit anyone else’s use of the Site. Prohibited 
      behavior includes, but is not limited to:</p>
      <ul>
        <li>Harassing or causing distress to any other person using the Site.</li>
        <li>Distributing any unlawful or malicious software or materials.</li>
        <li>Impersonating any person, or misrepresenting your identity or affiliation with any person.</li>
      </ul>

      <h2>3. Intellectual Property</h2>
      <p>All content and materials on this Site, including text, images, logos, software, 
      and other material (the "Content"), are the property of [Company Name] or its licensors. 
      You may not use, copy, reproduce, distribute, or create derivative works from any content 
      on this website without our prior written permission.</p>

      <h2>4. Software Use</h2>
      <p>Any software that is made available for download via the Site is the property of 
      [Company Name] and is protected by copyright laws. Any use of the software is governed 
      by the end-user license agreement (EULA) accompanying or included with the software.</p>

      <h2>5. Disclaimer of Warranties</h2>
      <p>This site and its content are provided "as is" without warranties of any kind, 
      either express or implied. [Company Name] does not guarantee that the website will 
      be available at all times or that it will be free from errors or viruses.</p>

      <h2>6. Limitation of Liability</h2>
      <p>In no event shall [Company Name], its directors, employees, or affiliates be liable 
      for any damages arising out of or in connection with your use of the Site. This limitation 
      of liability applies to all damages, including direct, indirect, incidental, punitive, 
      or consequential damages.</p>

      <h2>7. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of [Your Country], 
      without regard to its conflict of law principles. Any disputes relating to these Terms 
      will be subject to the exclusive jurisdiction of the courts of [Your Country].</p>

      <h2>8. Changes to the Terms</h2>
      <p>We reserve the right to modify or replace these Terms at any time. Any changes will 
      be posted on this page, and we encourage you to review the Terms periodically. Your 
      continued use of the Site after any changes are made will signify your acceptance of 
      the updated Terms.</p>

      <h2>9. Termination</h2>
      <p>We reserve the right to terminate your access to the Site at any time, without notice, 
      for conduct that we believe violates these Terms, or for any other reason.</p>

      <h2>10. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>[Company Name]<br />
      [Email Address]<br />
      [Phone Number]<br />
      [Company Address]</p>


      <div className="term-footer">
        <ContactPage/>
        <ContactFooter/>
      </div>
    </div>
    
  );
};

export default TermsAndConditions;
