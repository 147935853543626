import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Components/HomeBlogs.css'
import { ArrowForward } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const blogs = [
  {
    img: require('../Images/7076118.jpg'),
    date: 'June 13, 2024',
    author: 'By Jamal Naqvi,',
    title: 'Step-by-Step Guide to Azure Migration',
    description: 'Migrating virtual machines (VMs) to Azure can be challenging, especially with older versions of operating systems.'
  },
  {
    img: require('../Images/7076118.jpg'),
    date: 'June 13, 2024',
    author: 'By Jamal Naqvi,',
    title: 'AI Trends in 2024',
    description: 'Discover the emerging trends in AI and machine learning for the upcoming year.'
  },
  {
    img: require('../Images/7076118.jpg'),
    date: 'June 13, 2024',
    author: 'By Jamal Naqvi,',
    title: 'Data Engineering Best Practices',
    description: 'Learn the best practices for building and maintaining efficient data pipelines.'
  }
]

function HomeBlogs() {
  return (
    <div className='home-blogs-main'>
      <div className="blogs-heading">

        <Container>
          <Row>
            <Col lg='8'>
              <div className='blog-headings-details'>
                <h1>Blogs</h1>
                <p>Stay up-to-date with the latest advancements and news in AI, machine learning, and data engineering by following our blog for insightful updates and expert content.</p>
              </div>
            </Col>
            <Col lg='4'>
              <div className='blog-heading-button'>
                <button id='blogs-heading-btn'>Read more <ArrowForward /></button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="blogs-container">
        <Container>
          <Row>
            {blogs.map((blog, index) => (
              <Col lg='4' key={index}>
                <div className="blog-card">
                  <div className="img">
                    <img src={blog.img} alt="" />
                  </div>
                  <div className="blog-date">
                    <p>{blog.author}
                      {blog.date}</p>
                  </div>
                  <div className="blog-title">
                    <h4>{blog.title}</h4>
                  </div>
                  <div className="blog-des">
                    <p>{blog.description}</p>
                  </div>
                <Link to='https://nakwi.com/blog/'><button id='home-blog-button'>learn more</button></Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default HomeBlogs
