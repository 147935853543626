import { AccessTime, Build, Business, CheckCircle, Cloud, Computer, PhoneInTalk, PortableWifiOff, Star, } from '@mui/icons-material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/QA.css'

import ContactPage from '../../Components/ContactPage';
import ContactFooter from '../../Components/ContactFooter';
import TechnologyTabs from '../../Components/TechnologyTabs';

function QADevelopment() {

    const cardData = [
        {
            title: 'Automated Testing',
            description: 'nakwi utilizes a variety of automated QA testing tools to ensure the efficiency and reliability of its software solutions. These tools allow us to identify and address software issues early in the development process, ensuring a flawless product delivery.',
            icon: <Computer />
        },
        {
            title: 'Performance Testing',
            description: 'Performance testing is conducted to assess the efficiency and robustness of our software solutions. Our team of expert testers evaluates the performance of our applications under various conditions to guarantee their reliability and seamless operation.',
            icon: <Business />
        },
        {
            title: 'Security Testing',
            description: 'nakwi places a high priority on the security of our applications. To ensure this, we conduct comprehensive security testing to safeguard our software from potential cybersecurity threats and attacks, ensuring long-term protection and stability.',
            icon: <PortableWifiOff />
        },
        {
            title: 'Mobile Testing',
            description: 'Because of the rapid increase in mobile app development, we have made sure to have efficient mobile testing services that can help us deliver the right kind of mobile app development solutions',
            icon: <Cloud />
        },
        {
            title: 'Manual Testing',
            description: 'Our manual testing service is tailored specifically for custom software applications. Our skilled QA testers meticulously evaluate not only the user interface but also the entire functionality of the application, ensuring an optimal and seamless user experience.',
            icon: <Build />
        },

    ];

    return (
        <div className='qa-testing-main'>
            <div className='qa-main-container'>
                <div className='web-main-left'>
                    <h6>Quality Assurance (QA)</h6>
                    <h1>Empowering Businesses with Exceptional QA Testing Solutions.</h1>
                    <p>nakwi is committed to providing software solutions that are rigorously tested to meet global quality standards. By utilizing the latest QA tools and technologies, we ensure a bug-free experience, fostering a strong relationship of trust and reliability between our clients and our organization.</p>
                </div>
                <div className='main-left-inside'>
                    <Container>
                        <Row>
                            <Col lg='6'>
                                <span><AccessTime /></span>
                                <p>Global Time-Zone Alignment</p>
                            </Col>
                            <Col lg='6'>
                                <span><PhoneInTalk /></span>
                                <p>Complimentary Consultation</p>
                            </Col>
                            <Col lg='6'>
                                <span><Star /></span>
                                <p>Over 8 Years of Experience</p>
                            </Col>
                            <Col lg='6'>
                                <span><CheckCircle /></span>
                                <p>Over 120 Successful Projects</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* Qa service */}
            <div className='qa-services-main'>
                <div className='web-services-details'>
                    <h1>Services Offered</h1>
                    <p>At nakwi, we understand the critical role QA testing plays in software development. That's why we go the extra mile to offer a comprehensive range of testing services, ensuring you receive bug-free, high-performance software tailored to your needs.</p>
                </div>
                <div className='web-services-container'>
                    <Container>
                        <Row>
                            {cardData.map((card, index) => (
                                <Col lg='4' key={index}>
                                    <span>{card.icon}</span>
                                    <h2>{card.title}</h2>
                                    <hr />
                                    <p>{card.description}</p>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <div className='web-services-butoon'>
                        <button>Hire Dedicated Development Team</button>
                    </div>
                </div>
            </div>

            {/* technologies tab  */}

            <div className='technology-tab-main'>
                <div className='technology-tab-headings'>
                    <h1>Emerging Technologies</h1>
                    <p>Leveraging cutting-edge technology, we develop efficient solutions tailored to meet the needs of businesses of all sizes, from startups to Fortune 1000 companies.</p>
                </div>
                <div className='technology-tabs'>
                    <TechnologyTabs />
                </div>
            </div>
            {/* footer  */}

            <div className='qa-footer'>

                <ContactPage />
                <ContactFooter />
            </div>

        </div>
    )
}

export default QADevelopment
