import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as WebDevImg } from '../Images/web.svg';
import { ReactComponent as MobileDevImg } from '../Images/mobile.svg';
import { ReactComponent as DevOpsImg } from '../Images/devops.svg';
import { ReactComponent as InfrastructureImg } from '../Images/ui.svg';
import { ReactComponent as UiuxImg } from '../Images/infra.svg';
import { ReactComponent as QaImg } from '../Images/qa.svg';
import { ReactComponent as NakwiSvg } from '../Images/nakwi-logo.svg';
import '../Components/TopNavbar.css';
import { Link } from 'react-router-dom';

function TopNavbar() {
    return (
        <Navbar expand="lg" className="custom-navbar">
            <Container>
                <div className="topnav-logo">
                    <Link to='/'><NakwiSvg /></Link>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>

                        <div className="dropdown-container">
                            <NavDropdown
                                title="Services"
                                id="services-dropdown"
                            >
                                <NavDropdown.Item as={Link} to="/web-development">
                                    <WebDevImg /> Web Development
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/mobile-development">
                                    <MobileDevImg /> Mobile App Development
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/devops">
                                    <DevOpsImg /> DevOps
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/infrastructure">
                                    <InfrastructureImg /> Infrastructure
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/uiux-design">
                                    <UiuxImg /> UI/UX Design
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/qa-testing">
                                    <QaImg /> QA & Testing
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>

                        <div className="dropdown-container">
                            <NavDropdown
                                title="Company"
                                id="company-dropdown"
                            >
                                <NavDropdown.Item as={Link} to="/about">About Us</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/career'>Career</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Nav>
                    <div className="button-container">
                      <Link to='/quoit'><button>Get a Quote</button></Link>  
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavbar;
