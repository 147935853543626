import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../Components/DevopsTab.css'
import { Col, Container, Row } from 'react-bootstrap';
import '../Components/UITabs.css'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UITabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                    <Tab label="UI/UX" {...a11yProps(0)} />
                    <Tab label="Prototyping" {...a11yProps(1)} />
                    <Tab label="Animations" {...a11yProps(2)} />
                    <Tab label="Colaboration" {...a11yProps(3)} />
                    <Tab label="Illustrator& Graphic Design" {...a11yProps(4)} />

                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className='UX-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/figma/figma-icon.svg' alt=''/>
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-xd-logo-39468DE5D4-seeklogo.com.png' alt='' width={65}/>
                                <h1>Adobe XD</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/sketchapp/sketchapp-icon.svg' alt=''/>
                                <h1>Sketch</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/adobe_illustrator/adobe_illustrator-icon.svg' alt=''/>
                                <h1>Adobe Illustrator</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-photoshop-logo-7B88D7B5AA-seeklogo.com.png' alt='' width={65}/>
                                <h1>Adobe Photoshop</h1>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className='Prototyping-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/figma/figma-icon.svg' alt=''/>
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-xd-logo-39468DE5D4-seeklogo.com.png' alt='' width={65}/>
                                <h1>Adobe XD</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/sketchapp/sketchapp-icon.svg' alt='' />
                                <h1>Sketch</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/invisionapp/invisionapp-icon.svg'  alt=''/>
                                <h1>Invision Studio</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <div className='animation-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/figma/figma-icon.svg' alt='' />
                                <h1>Figma</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-after-effects-logo-960B473FE4-seeklogo.com.png' alt='' width={65}/>
                                <h1>After Effects</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-animate-logo-D9B0CB24F0-seeklogo.com.png' alt='' width={65}/>
                                <h1>Adobe Animate</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <div className='colaboration-main'>
                    <Container>
                        <Row>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/zeplinio/zeplinio-icon.svg'  alt=''/>
                                <h1>Zeplin</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/invisionapp/invisionapp-icon.svg' alt=''/>
                                <h1> InVision</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://seeklogo.com/images/A/adobe-creative-cloud-2020-new-logo-B6324473C2-seeklogo.com.png' alt='' width={65}/>
                                <h1>Adobe Cloud</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <div className='Illustrator-main'>
                    <Container>
                        <Row>
                        <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/adobe_illustrator/adobe_illustrator-icon.svg' alt='' />
                                <h1>Illustrator</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThiX0VEBawicSGWOEDe7-5AJaSvXx9fyVZZA&s' alt='' width={65}/>
                                <h1>Photoshop</h1>
                            </Col>
                            <Col lg='4' md='3' xs='6'>
                                <img src='https://www.vectorlogo.zone/logos/sketchapp/sketchapp-icon.svg' alt='' />
                                <h1>Sketch</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </CustomTabPanel>
        </Box>
    );
}
