import { AccessTime, CheckCircle, PhoneInTalk, Star } from '@mui/icons-material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Services/Infra.css'
import TechnologyTabs from '../../Components/TechnologyTabs'
import ContactFooter from '../../Components/ContactFooter'
import ContactPage from '../../Components/ContactPage'


function InfraDevelopment() {
    return (
        <div className='infra-main'>
            <div className='qa-main-container'>
                            <div className='web-main-left'>
                                <h6>Infrastruture</h6>
                                <h1>Empowering Businesses with Exceptional QA Testing Solutions.</h1>
                                <p>Nakwi is committed to providing software solutions that are rigorously tested to meet global quality standards. By utilizing the latest QA tools and technologies, we ensure a bug-free experience, fostering a strong relationship of trust and reliability between our clients and our organization.</p>
                            </div>
                            <div className='main-left-inside'>
                                <Container>
                                    <Row>
                                        <Col lg='6'>
                                            <span><AccessTime /></span>
                                            <p>Global Time-Zone Alignment</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><PhoneInTalk /></span>
                                            <p>Complimentary Consultation</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><Star /></span>
                                            <p>Over 8 Years of Experience</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span><CheckCircle /></span>
                                            <p>Over 120 Successful Projects</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
            </div>


            {/* technologies tab  */}

            <div className='technology-tab-main'>
                <div className='technology-tab-headings'>
                    <h1>Emerging Technologies</h1>
                    <p>Leveraging cutting-edge technology, we develop efficient solutions tailored to meet the needs of businesses of all sizes, from startups to Fortune 1000 companies.</p>
                </div>
                <div className='technology-tabs'>
                    <TechnologyTabs />
                </div>
            </div>
            {/* footer  */}

            <div className='qa-footer'>
                <ContactPage/>
                <ContactFooter />
            </div>
        </div>
    )
}

export default InfraDevelopment
