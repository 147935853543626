import React from 'react'
import '../Components/ContactFooter.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Email, Phone } from '@mui/icons-material'
import { ReactComponent as NakwiSvg } from '../Images/nakwi-logo.svg';
import { Link } from 'react-router-dom'

function ContactFooter() {
    return (
        <div className='Contact-footer-main'>
            <div className="contact-footer-container">
                <Container>
                    <Row>
                        <Col lg='6'>
                            <div className="contact-page-right">
                                <div className="contact-page-right-info">
                                    <h1>Contact Us...</h1>
                                    <hr />
                                    <h3><Email />hi@nakwi.com</h3>
                                    {/* <h3><Phone />+123-567-124</h3> */}
                                </div>
                                <div className="contact-page-social">
                                    <h1>Follow Us...</h1>
                                    <hr />
                                    <div className="contact-page-socialIcon">
                                        <span><i className="fab fa-instagram"></i></span>
                                        <span><i className="fab fa-facebook-f"></i></span>
                                        <span><i className="fab fa-linkedin-in"></i></span>
                                        <span><i className="fab fa-twitter"></i></span>
                                    </div>
                                </div>
                                <div className="contact-page-newsletter">
                                    <h1>Sign Up for our Newsletter</h1>
                                    <input type="text" placeholder='Enter your email' />
                                    <button>Submit</button>
                                </div>
                            </div>
                        </Col>
                        <Col lg='6'>
                            <Container>
                                <Row>
                                    <Col lg='6'>
                                        <div className="contact-page-links">
                                            <h1>Quick Links</h1>
                                            <hr />
                                            <ul>
                                                <Link to='/' style={{ textDecoration: 'none' }}><li>Home</li></Link>
                                                <Link to='/about' style={{ textDecoration: 'none' }}><li>About Us</li></Link>
                                                <Link to='https://nakwi.com/blog/' style={{ textDecoration: 'none' }}><li>Blogs</li></Link>
                                                <Link to='/quoit' style={{ textDecoration: 'none' }}><li>Contact Us</li></Link>
                                                <Link to='/career' style={{ textDecoration: 'none' }}><li>Careers</li></Link>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col lg='6'>
                                        <div className="contact-page-services">
                                            <h1>Services</h1>
                                            <hr />
                                            <ul>
                                            <Link to='/web-development' style={{ textDecoration: 'none' }}><li>Web Development</li></Link>
                                            <Link to='/mobile-development' style={{ textDecoration: 'none' }}><li>Mobile Development</li></Link>
                                            <Link to='/infrastructure' style={{ textDecoration: 'none' }}><li>InfraStruture</li></Link>
                                            <Link to='/devops' style={{ textDecoration: 'none' }}><li>DevOps</li></Link>
                                            <Link to='/uiux-design' style={{ textDecoration: 'none' }}><li>UI/UX Design</li></Link>
                                            <Link to='/qa-testing' style={{ textDecoration: 'none' }}><li>Qa Testing</li></Link>
                                            </ul>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                            <div className="contact-page-footer-logo">
                                <NakwiSvg width={150} />
                                <p>The goal is to deliver quality-focused software products to aspiring businesses.</p>
                                <hr />
                                <div className="copyright">
                                    <span>Copyright@ Nakwi 2024</span>
                                    <div className="conditions">
                                        <Link to="/terms" style={{ textDecoration: 'none' }}>
                                            <span>Terms & Conditions</span>
                                        </Link>
                                        <span>Privacy Policy</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ContactFooter
